'use strict';

import { getLogger } from 'shared/js/dev-mode';
const logger = getLogger();

const util = require('./util');

// Refactored in a more OOP way to avoid issue of lost context (this|self) on importing within
// other JS modules.
let CartWidget = function () {
    this.container = null;
    this.context = null;
    this.getCartModelURL = null;
    this.isDisengaged = false;
    this.isInitialized = false;
    this.isActive = false;
    this.data = false;
    this.htmlSnippet = null;
    this.pageSession = Math.floor(Math.random() * 1000000);
    this.basketMaxAge = null;
    this.ghostElem = null;
    this.fadeTime = 800;
    /**
    * Initiate widget
    * @param {string} context - storefront|checkout
    * @return {*}
    */
    this.init = function (context) {
        var self = this;
        // container
        self.container = $('#cartwidget');
        self.ghostElem = $('#cart-loader');
        self.cartEmptyRedirect = window.location.search.indexOf('cartEmpty') > -1;
        // settings
        self.context = context || 'checkout';
        self.basketMaxAge = Number.isInteger(self.container.data('basket-max-age')) ? self.container.data('basket-max-age') : 60 * 1000;
        // Initiating central urls
        self.getCartModelURL = self.container.data('get-cart-model');
        self.renderURL = self.context == 'storefront' ? self.container.data('get-render-minicart') : self.container.data('get-render-cart');
        self.pushCheckoutGTM = (context === 'checkout' && window.GTM_ENABLED && window.GTM_CONTAINER_ID !== 'null');

        if (self.container.length == 0) {
            self.isDisengaged = true;
            return self;
        }

        self.data = self.getCartModel();

        self.htmlSnippet = self.getSnippet();
        // console.log('cartwidget initiate context', context, self.renderURL, 'self.htmlSnippet', !!self.htmlSnippet);
        // Initiating listeners for the widget itself, ie these are not listeners to any
        // other widget or section of the DOM -- only wigdet internal updates.
        self.initListeners();

        return self;
    };
    this.update = function (forceRender) {
        var self = this;

        if (self.isDisengaged) {
            return self;
        }

        self.suspend();
        $.ajax({
            url: self.getCartModelURL,
            type: 'get',
        })
            .done((res) => {
                // console.log('update res', res.cartModel);
                // Only trigger re-render in case there is any update to the
                // cart model.
                // @TODO make more granular; certain cart changes
                // may not warrant re-render, or, could update single components.
                let isUpdated = self.storeCartModel(res.cartModel);
                if (isUpdated) {
                    $(self).trigger('self:update', self.getCartModel());
                }
                if (isUpdated || forceRender) {
                    return self.render();
                }
            })
            .fail((res) => {
                logger.error('update', res);
            })
            .always(() => {
                self.resume();
            });

        return self;
    };
    this.checkAndPushCheckoutGTM = function () {
        var self = this;
        if (self.pushCheckoutGTM) {
            document.dispatchEvent(
                new CustomEvent('GTM-push-checkout', {
                    detail: {
                        step: 1,
                        productsElements: '.checkout-cart-container .product-summary-block .product-line-item ',
                        currencyCode: $(document.querySelector('.container.wrapper')).data('currency')
                    }
                })
            );
        }
    };
    this.render = function () {
        var self = this;

        if (self.isDisengaged) {
            return self;
        }

        let cartModel = self.getCartModel();

        if (!cartModel) {
            return self.update();
        }

        $(self).trigger('self:pre-render', cartModel);
        // A core concept of this widget is the possibility to load cart w/o
        // server requets on page load.
        // This code checks if this is the first time we request to render
        // the basket, in that case, if we actually already have a version stored
        // in the local storage, we will load that.
        if (!self.isInitialized && self.htmlSnippet) {
            // fade out ghost and fade in actual cart
            self.container.fadeOut(self.fadeTime / 2, function () {
                self.container.empty().html(self.htmlSnippet).fadeIn(self.fadeTime / 2);
                $(self).trigger('self:render', cartModel);
                self.checkAndPushCheckoutGTM();
            });
        } else {
            $.ajax({
                url: self.renderURL,
                data: {
                    cartModel: JSON.stringify(cartModel),
                    isCheckout: self.context == 'checkout'
                },
                type: 'post'
            })
                .done((htmlSnippet) => {
                    self.container.fadeOut(self.fadeTime / 2, function () {
                        self.container.empty().html(self.htmlSnippet).fadeIn(self.fadeTime / 2);
                        $(self).trigger('self:render', cartModel);
                        self.checkAndPushCheckoutGTM();
                    });
                    self.storeSnippet(htmlSnippet, cartModel);
                })
                .fail((res) => {
                    logger.error('render', res);
                })
                .always(() => {
                    self.resume();
                });
        }
        self.isInitialized = true;

        return self;
    };
    this.ghost = function () {
        var self = this;
        self.container.empty().html(self.ghostElem.html());

        return self;
    };
    this.resume = function () {
    // @TODO implement
        var self = this;
        self.isActive = true;
    };
    this.suspend = function () {
    // @TODO implement
        var self = this;
        self.isActive = false;
    };
    this.clearSnippets = function () {
        localStorage.removeItem('checkout_htmlSnippet');
        localStorage.removeItem('storefront_htmlSnippet');
    };
    this.storeCartModel = function (cartModel) {
        var self = this;
        cartModel = util.filterCartModelObject(cartModel);
        cartModel.sessionID = self.getSessionID();

        localStorage.setItem('lastUpdate', Date.now());

        if (!util.isEqual(self.getCartModel(), cartModel)) {
            self.emitChanges(self.getCartModel(), cartModel);
            self.data = cartModel;

            try {
                localStorage.setItem('cart', JSON.stringify(cartModel));
            } catch (e) {
                if (e.name === 'QuotaExceededError') {
                    /* Clear save snippets in case quota error to not to render wrong cart information and save
                       the cart model since it's the main data to keep in local storeage */
                    self.clearSnippets();
                    localStorage.setItem('cart', JSON.stringify(cartModel));
                }
            }

            return true;
        }
        return false;
    };
    this.getCartModel = function () {
        var self = this;

        if (self.isSessionDirty()) {
            return null;
        }

        if (self.data && self.data.hasOwnProperty('sessionID') && self.data.sessionID == self.getSessionID()) {
            if (!self.cartEmptyRedirect || (self.cartEmptyRedirect && self.data.items.length === 0)) {
                return self.data;
            }
        }

        try {
            let cartModelStorage = JSON.parse(localStorage.getItem('cart'));
            if (cartModelStorage && cartModelStorage.hasOwnProperty('sessionID') && cartModelStorage.sessionID == self.getSessionID()) {
                if (!self.cartEmptyRedirect || (self.cartEmptyRedirect && cartModelStorage.items.length === 0)) {
                    return cartModelStorage;
                }
            }
        } catch (e) {
            logger.error('could not parse cart model storage', e);
        }

        if (self.cartEmptyRedirect) {
            self.cartEmptyRedirect = false;
        }

        return null;
    };
    this.storeSnippet = function (htmlSnippet, cartModel) {
        var self = this;
        var cartStorageItem = {
            time: Date.now(),
            htmlSnippet: htmlSnippet,
            signature: util.hash(JSON.stringify(cartModel))
        };

        var cartStorage = [];
        try {
            let storageItem = localStorage.getItem(self.context + '_htmlSnippet');
            cartStorage = util.isJSON(storageItem) ? JSON.parse(storageItem) : [];
        } catch (e) {
            logger.error('could no parse stored cartStorage array', e);
        }

        // Upsert cartStorage array
        var cartStorageIndex = cartStorage.findIndex((_cartStorageItem) => { return _cartStorageItem.signature == cartStorageItem.signature; });
        logger.log('found storage index', cartStorageIndex);
        if (cartStorageIndex !== -1) {
            cartStorage[cartStorageIndex] = cartStorageItem;
        } else {
            cartStorage.push(cartStorageItem);
        }

        // Remove too old carts
        cartStorage = cartStorage.filter((_cartStorageItem) => {
            return (Date.now() - _cartStorageItem.time) < self.basketMaxAge;
        });

        try {
            localStorage.setItem(self.context + '_htmlSnippet', JSON.stringify(cartStorage));
        } catch (e) {
            if (e.name === 'QuotaExceededError') {
                // Clear save snippets in case quota error to not to render wrong cart information
                self.clearSnippets();
            }
        }

        self.htmlSnippet = htmlSnippet;
    };
    this.getSnippet = function () {
        var self = this;
        // localStorage entries must be strings, hence the array string
        var cartStorage = localStorage.getItem(self.context + '_htmlSnippet') || '[]';
        var htmlSnippet = null;
        try {
            cartStorage = JSON.parse(cartStorage);
            // Filter out cart snippets that are wrong ID or too old
            var cartStorageItem = cartStorage.find((_cartStorageItem) => {
                let deltaTime = Date.now() - _cartStorageItem.time;
                return _cartStorageItem.signature == util.hash(JSON.stringify(self.data)) && // version is based on same cart model
                    deltaTime < self.basketMaxAge; // version has not gone stale
            });
            htmlSnippet = cartStorageItem && cartStorageItem.htmlSnippet;
        } catch (e) {
            logger.error('could not parse cart storage', e);
        }

        return htmlSnippet;
    };
    this.getSessionID = function () {
        let cookieSessionID = util.getSessionID();
        return cookieSessionID == 'n/a' ? self.pageSession : cookieSessionID;
    };
    this.isSessionDirty = function () {
        var self = this;
        let lastUpdate = localStorage.getItem('lastUpdate');
        lastUpdate = lastUpdate ? parseInt(lastUpdate, 10) : 0;
        return (Date.now() - lastUpdate) >= self.basketMaxAge;
    };
    this.clearData = function () {
        ['storefront', 'checkout'].forEach(function (context) {
            localStorage.removeItem(context + '_htmlSnippet');
        });
        localStorage.removeItem('cart');
    };
    this.emitChanges = function (oldCartModel, newCartModel) {
        var self = this;

        // If either object does not exist there is not much reason to compare
        // changes between them
        if (!oldCartModel || !newCartModel) {
            return;
        }

        try {
            let modelDifferences = util.objectDifference(oldCartModel.totals, newCartModel.totals);

            if (modelDifferences.hasOwnProperty('grandTotal')) {
                $(self).trigger('self:update:total', newCartModel.totals);
            }
            if (modelDifferences.hasOwnProperty('subTotal')) {
                $(self).trigger('self:update:subtotal', newCartModel.totals.subTotal);
            }
            if (modelDifferences.hasOwnProperty('orderLevelDiscountTotal')) {
                $(self).trigger('self:update:orderleveldiscount', newCartModel.totals.subTotal);
            }
        } catch (e) {
            logger.error('[emitChanges] ', e, oldCartModel, newCartModel);
        }
    };
    this.initListeners = function () {
        var self = this;
        // Core concept of the cart widget:
        // cartModel is stored in the locale storage of the browser.
        // When there is an update in the local storage we analyze if there
        // to see if there is any differences, and if there is one we render
        // a new cart based on that.
        // WARNING: local storage listeners are NOT cross domain. However, on
        // all instance but Development and Production we will be one the same.
        // This means that events on GG will also be heard on the other sites
        // in the same browser.
        window.addEventListener('storage', function (event) {
            if (event.key != 'cart') {
                return;
            }

            let oldValueObject = util.isJSON(event.oldValue) ? JSON.parse(event.oldValue) : event.oldValue;
            let newValueObject = util.isJSON(event.newValue) ? JSON.parse(event.newValue) : event.newValue;

            if (!util.isCartModel(newValueObject) || util.isEqual(oldValueObject, newValueObject)) {
                return;
            }

            // Below code has been commented out since there is a problem
            // with the scenario where one update on one site also triggers
            // an update on all the others. In other words, this was originally
            // how it looke.
            // self.update();
            self.storeCartModel(newValueObject);
            $(self).trigger('self:update', self.getCartModel());
            self.render();
        }, false);

        console.log('initiate  cart:update listeners');

        // Periferral concept of the cart widget:
        // Since original cart script holds a lot of code for updating
        // many things about the basket this scrip will intially act as
        // bridge between it and communications with the new checkouts.
        // Eventually this concept is supposed to be replaced by a
        // more holistic solution.
        $('body').on('cart:update', function (event, topic, cartModel) {
            logger.log('heard cart:update event');

            if (self.cartEmptyRedirect) {
                self.cartEmptyRedirect = false;
            }

            if (!util.isCartModel(cartModel)) {
                logger.warn('cartModel could not be assertained');
                // From the event recieved we could not interpret cartModel arg
                // as an actually cartModel. But we know there has been an update
                // to the cart so we trigger a server side update.
                // This should not be necessary, but will probably be so frequently.
                self.update();
            } else {
                let isUpdated = self.storeCartModel(cartModel);
                if (isUpdated) {
                    self.render();
                }
            }
            switch (topic) {
            case 'delete_item':
            case 'delete_coupon':
                $(self).trigger('self:remove', [cartModel]);
                break;
            case 'update_quantity':
            case 'add_coupon':
            case 'update_product_clobal':
            case 'add_to_cart':
                $(self).trigger('self:add', [cartModel]);
                break;
            default:
                logger.error('Case not covered');
            }
        });
    };
    this.registerCallback = function (ids, func) {
        logger.log('register callback', ids, func);
        var self = this;

        if (self.isDisengaged) {
            return self;
        }

        if (!Array.isArray(ids)) {
            ids = [ids];
        }

        ids.forEach(function (id) {
            switch (id) {
            case 'cartwidget:initialize':
                $(self).on('self:init', func);
                break;
            case 'cartwidget:update':
                $(self).on('self:update', func);
                break;
            case 'cartwidget:render':
                $(self).on('self:render', func);
                break;
            case 'cartwidget:pre-render':
                $(self).on('self:pre-render', func);
                break;
            case 'cartwidget:add':
                $(self).on('self:add', func);
                break;
            case 'cartwidget:remove':
                $(self).on('self:remove', func);
                break;
            case 'cartwidget:update:subtotal':
                $(self).on('self:update:subtotal', func);
                break;
            case 'cartwidget:update:orderleveldiscount':
                $(self).on('self:update:orderleveldiscount', func);
                break;
            case 'cartwidget:update:total':
                $(self).on('self:update:total', func);
                break;
            default:
                logger.error('state not covered', id);
            }
        });
    };
};

module.exports = new CartWidget();
